@import './font_xingxing/iconfont.css';
@import 'rc-rate/assets/index.css';
ul.rc-rate {
  @apply text-xs;
  .rc-rate-star {
    @apply mr-0;
  }
  .rc-rate-star-half .rc-rate-star-first,
  .rc-rate-star-full .rc-rate-star-second {
    @apply text-yellow;
  }
  .rc-rate-star-half:hover .rc-rate-star-first,
  .rc-rate-star-full:hover .rc-rate-star-second {
    @apply text-yellow;
  }
}
